/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.toast-container {
    position: fixed;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 2rem;
}

    .toast-container .toast {
        flex-basis: unset;
    }

        .toast-container .toast.toast-success {
            background-color: #80ff80;
        }

        .toast-container .toast.toast-danger {
            background-color: #ff6666;
        }

.vertical-center {
    min-height: 100%; /* Fallback for browsers do NOT support vh unit */
    /*min-height: 100vh;*/ /* These two lines are counted as one :-)       */
    display: flex;
    align-items: center;
}

.full-width-tabs > ul.nav.nav-pills {
    display: table;
    width: 100%;
    table-layout: fixed;
}

    .full-width-tabs > ul.nav.nav-pills > li {
        float: none;
        display: table-cell;
    }

        .full-width-tabs > ul.nav.nav-pills > li > a {
            text-align: center;
        }

.truncate-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
}

.btn-outline-success:focus, .btn-outline-success:hover, .btn-outline-success:active {
    color: #28a745 !important;
    background-color: transparent !important;
    border-color: #28a745 !important;
    box-shadow: none !important;
}

.valueItemDrop .dropdown-menu {
    padding: 0 10px;
    top: 22px;
    width: 100%;
    box-shadow: none;
    border-radius: none;
}

.valueItemDrop .checkbox label {
    padding-left: 25px;
    font-weight: bold;
}

    .valueItemDrop .checkbox label:before {
        border: 1px solid #ccc;
        border-radius: 3px;
    }

.valueItemDrop .selectbox {
    border-radius: 5px;
    padding: 3px 5px;
    width: 100%;
    border: 1px solid #A4A4A4;
    float: right;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: 163px 8px;
    background-color: white;
}

.balance-negative {
    color: darkred;
}

.balance-positive {
    color: darkgreen;
}

.navbar-nav {
    flex-flow: row nowrap;
}

.navbar {
    flex-flow: row nowrap;
    display: inline-flex;
}

#navbarNav {
    display: inline-block;
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    background-color: rgba(250, 250, 250, 0.95);
    z-index: 1;
    overflow-x: hidden;
    -webkit-transition: width 0.35s ease-in-out;
    -moz-transition: width 0.35s ease-in-out;
    -o-transition: width 0.35s ease-in-out;
    transition: width 0.35s ease-in-out;
}

    #navbarNav.show {
        width: calc(100% - 50px);
    }

.collapse {
    visibility: hidden;
}

    .collapse.show {
        visibility: visible;
        display: block;
    }

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility;
    -webkit-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
}

    .collapsing.width {
        -webkit-transition-property: width, visibility;
        transition-property: width, visibility;
        width: 0;
        height: auto;
    }

body {
    height: 100vh;
}

.back, .back:hover, .back:active, .back:focus {
    background: none;
    border: none;
    outline: none;
    color: #0366d6;
}

.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

    .lds-spinner div {
        transform-origin: 40px 40px;
        animation: lds-spinner 1.2s linear infinite;
    }

        .lds-spinner div:after {
            content: " ";
            display: block;
            position: absolute;
            top: 3px;
            left: 37px;
            width: 6px;
            height: 18px;
            border-radius: 20%;
            background: blue;
        }

        .lds-spinner div:nth-child(1) {
            transform: rotate(0deg);
            animation-delay: -1.1s;
        }

        .lds-spinner div:nth-child(2) {
            transform: rotate(30deg);
            animation-delay: -1s;
        }

        .lds-spinner div:nth-child(3) {
            transform: rotate(60deg);
            animation-delay: -0.9s;
        }

        .lds-spinner div:nth-child(4) {
            transform: rotate(90deg);
            animation-delay: -0.8s;
        }

        .lds-spinner div:nth-child(5) {
            transform: rotate(120deg);
            animation-delay: -0.7s;
        }

        .lds-spinner div:nth-child(6) {
            transform: rotate(150deg);
            animation-delay: -0.6s;
        }

        .lds-spinner div:nth-child(7) {
            transform: rotate(180deg);
            animation-delay: -0.5s;
        }

        .lds-spinner div:nth-child(8) {
            transform: rotate(210deg);
            animation-delay: -0.4s;
        }

        .lds-spinner div:nth-child(9) {
            transform: rotate(240deg);
            animation-delay: -0.3s;
        }

        .lds-spinner div:nth-child(10) {
            transform: rotate(270deg);
            animation-delay: -0.2s;
        }

        .lds-spinner div:nth-child(11) {
            transform: rotate(300deg);
            animation-delay: -0.1s;
        }

        .lds-spinner div:nth-child(12) {
            transform: rotate(330deg);
            animation-delay: 0s;
        }

@keyframes lds-spinner {
    0% {
        opacity: 0.6;
    }

    100% {
        opacity: 0;
    }
}

.loader {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: white;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.33s, opacity 0.33s linear;
}

    .loader.show {
        visibility: visible;
        opacity: 0.6;
        transition-delay: 0s;
    }

input:disabled, input[readonly] {
    background-color: #999 !important;
}
